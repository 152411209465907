import React from "react";

//-- Layout props type --
//------------------------------------------------------------------------------
type ComponentProps = {
  readonly children?: React.ReactNode | null;
  readonly noGap?: boolean;
  readonly large?: boolean;
  readonly className?: string;
};

//-- Component --
//------------------------------------------------------------------------------
export default function Container({
  children,
  noGap = false,
  large = false,
  className = "",
}: ComponentProps) {
  const lpx = large ? "px-6 md:px-8 xl:px-6" : "px-6 md:px-8 xl:px-24";
  const px = noGap ? "" : lpx;
  const classNames = `max-w-screen-2xl mx-auto ${px} ${className}`;
  return <div className={classNames}>{children}</div>;
}
