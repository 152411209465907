import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { CSSProperties } from "react";

//-- Image node type --
//------------------------------------------------------------------------------
type ImageNode = {
  readonly altText: string | null;
  readonly localFile: any;
};

//-- Props type --
//------------------------------------------------------------------------------
type ImageProps = {
  readonly image:
    | {
        readonly node: ImageNode | null | undefined;
      }
    | null
    | undefined;
  readonly className?: string | null;
  readonly style?: CSSProperties;
};

//-- Component --
//------------------------------------------------------------------------------
export default function Image({ image, className, style }: ImageProps) {
  if (typeof image?.node !== "undefined" && image?.node !== null) {
    const img = getImage(image.node.localFile);
    if (typeof img !== "undefined") {
      return (
        <GatsbyImage
          className={className ?? ""}
          image={img}
          alt={image.node.altText ?? ""}
          style={style}
          imgStyle={{ objectFit: "cover" }}
        />
      );
    }
  }

  return null;
}
