import { graphql, Link, PageProps, useStaticQuery } from "gatsby";
import React from "react";
import Container from "../components/container";
import Image from "../components/image";
import Logo from "../assets/svg/logo.svg";
import _ from "lodash";

//-- Types --
//------------------------------------------------------------------------------
type LayoutQueryProps = {
  readonly data: Queries.LayoutDataQuery;
  readonly slug?: string | null;
};

interface LayoutProps extends PageProps {}

type SiteContextData = {
  readonly title: string | null;
  readonly description: string | null;
  readonly language: string | null;
  readonly pageSlug?: string | null;
  setPageSlug: (slug: string) => any;
} | null;

export const SiteContext = React.createContext<SiteContextData>(null);

//-- Component --
//------------------------------------------------------------------------------
export default function Layout(props: LayoutProps) {
  const layoutQuery = graphql`
    query LayoutData {
      settings: wp {
        generalSettings {
          title
          description
          language
        }
        siteSettings {
          fields: siteSettingsFields {
            header {
              logo {
                node {
                  localFile {
                    publicURL
                  }
                }
              }
            }
            footer {
              locations {
                locationMap {
                  link
                  image {
                    node {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            placeholder: BLURRED
                            layout: FULL_WIDTH
                            formats: [AVIF, WEBP, AUTO]
                          )
                        }
                      }
                    }
                  }
                }
                title
                address
                email
                phone
              }
              quote {
                content
                title
                designation
              }
            }
          }
        }
      }
      nav: allWpMenuItem(
        filter: { locations: { eq: COREDO_HEADER_MENU } }
        sort: { order: ASC }
      ) {
        nodes {
          id
          label
          url
          target
          path
        }
      }
    }
  `;

  const data = useStaticQuery<Queries.LayoutDataQuery>(layoutQuery);
  const [slug, setSlug] = React.useState("");
  const paths = _.compact(_.split(props.location.pathname, "/"));
  const pageSlug =
    typeof paths[0] !== "undefined" && paths[0] === ""
      ? "home"
      : _.trimEnd(_.replace(paths[0], "/", ""), "/");
  const siteContext = React.useMemo<SiteContextData>(() => {
    const sc: SiteContextData = {
      title: "",
      description: "",
      language: "",
      pageSlug: pageSlug,
      setPageSlug: (slug: string) => setSlug(slug),
    };
    return sc;
  }, [slug]);

  return (
    <SiteContext.Provider value={siteContext}>
      <main className={`overflow-hidden ${slug}`}>
        <Header data={data} />
        {props.children}
        <Footer data={data} slug={slug} />
      </main>
    </SiteContext.Provider>
  );
}

//-- Header --
//------------------------------------------------------------------------------
function Header({ data }: LayoutQueryProps) {
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const [isFixed, setIsFixed] = React.useState(false);
  const nClass = isFixed ? "is-fixed" : "";
  const headerSettings = data.settings?.siteSettings?.fields?.header;
  const logoUrl = headerSettings?.logo?.node.localFile?.publicURL;
  const logoAlt =
    data.settings?.generalSettings?.title === null
      ? "Logo"
      : data.settings?.generalSettings?.title;

  const [isNavOpen, setIsNavOpen] = React.useState(false);
  React.useEffect(() => {
    handleVisibleButton();
    window.addEventListener("scroll", handleVisibleButton);
  });

  const handleVisibleButton = () => {
    const position = window.scrollY;
    const limit = 100;
    setScrollPosition(position);
    if (scrollPosition > limit) {
      return setIsFixed(true);
    } else if (scrollPosition < limit) {
      return setIsFixed(false);
    }
  };

  const siteContext = React.useContext(SiteContext);
  const paths = siteContext?.pageSlug;
  return (
    <>
      <nav
        className={`mobile-menu invisible fixed left-0 top-0 z-50 h-full w-72 overflow-y-auto border-r-4 border-white bg-primary text-white shadow-md lg:hidden ${isNavOpen ? "showMenuNav" : "hideMenuNav"}`}
      >
        {typeof logoUrl !== "undefined" && logoUrl !== null ? (
          <a
            href="/"
            className="m-6 block h-auto w-[120px] cursor-pointer lg:w-[180px]"
            onClick={() => setIsNavOpen(false)}
          >
            <img className="block h-auto w-full" src={logoUrl} alt={logoAlt} />
          </a>
        ) : null}
        <ul className="">
          {data.nav.nodes.map((item) => {
            const linkPath =
              item.path !== null
                ? _.trimEnd(_.replace(item.path, "/", ""), "/")
                : "";
            return (
              <li
                key={item.id}
                className="block border-t-[1px] border-purple-100/20 uppercase"
              >
                {item.url !== null && (
                  <Link
                    to={item.path ?? ""}
                    className={`block px-6 py-4 font-medium tracking-wider opacity-55 hover:!text-secondary hover:opacity-95 ${paths === linkPath ? "linkActive" : ""}`}
                    onClick={() => setIsNavOpen(false)}
                  >
                    {item.label}
                  </Link>
                )}
              </li>
            );
          })}
        </ul>
      </nav>
      <div
        className={`invisible fixed bottom-0 left-0 right-0 top-0 z-40 bg-primary/70 backdrop-blur lg:hidden ${isNavOpen ? "showDrop" : "hideDrop"}`}
        onMouseDown={() => setIsNavOpen((prev) => !prev)}
      >
        &nbsp;
      </div>
      <header
        className={`fixed left-0 top-0 z-30 w-full border-b-8 border-white bg-primary py-5 text-white transition-[padding] ${nClass}`}
      >
        <Container>
          <div className="flex items-center justify-between">
            {typeof logoUrl !== "undefined" && logoUrl !== null ? (
              <a
                href="/"
                className="block h-auto w-[120px] cursor-pointer lg:w-[180px]"
              >
                <img
                  className="block h-auto w-full"
                  src={logoUrl}
                  alt={logoAlt}
                />
              </a>
            ) : null}
            <nav className="hidden lg:block">
              <ul className="clearfix text-xs uppercase tracking-wider">
                {data.nav.nodes.map((item) => {
                  const linkPath =
                    item.path !== null
                      ? _.trimEnd(_.replace(item.path, "/", ""), "/")
                      : "";
                  return (
                    <li key={item.id} className="ml-10 lg:float-left">
                      {item.url !== null && (
                        <Link
                          to={item.path ?? "#"}
                          className={`block font-bold tracking-wider opacity-55 hover:opacity-95 ${paths === linkPath ? "linkActive" : ""}`}
                        >
                          {item.label}
                        </Link>
                      )}
                    </li>
                  );
                })}
              </ul>
            </nav>
            <div
              className="block cursor-pointer space-y-2 lg:hidden"
              onMouseDown={() => setIsNavOpen((prev) => !prev)}
            >
              <span className="block h-0.5 w-8 bg-white"></span>
              <span className="block h-0.5 w-8 bg-white"></span>
              <span className="block h-0.5 w-8 bg-white"></span>
            </div>
          </div>
        </Container>
      </header>
    </>
  );
}

//-- Footer --
//------------------------------------------------------------------------------
function Footer({ data, slug }: LayoutQueryProps) {
  const fields = data.settings?.siteSettings?.fields?.footer;
  return (
    <footer className="bg-primary text-white">
      <div className="bg-warning py-8 sm:pb-28 sm:pt-16">
        <Container>
          <div className="md:flex md:gap-6 lg:gap-8">
            {fields?.locations !== null &&
              fields?.locations.map((item, idx) => (
                <div
                  key={"footerlocationitem" + idx}
                  className="my-12 md:my-0 md:w-1/3"
                >
                  {slug === "contact-us" && (
                    <div className="mb-4 inline-block w-full overflow-hidden rounded border-2 border-secondary bg-secondary shadow-md md:-mt-52">
                      <a
                        className="gr-overlay block"
                        href={item?.locationMap?.link ?? "#"}
                        target="_blank"
                        title="click to view in google maps"
                      >
                        <Image image={item?.locationMap?.image} />
                      </a>
                    </div>
                  )}
                  <div className="pl-2">
                    <h6 className="text-lg font-bold">{item?.title}</h6>
                    <address
                      className="mb-5 mt-2 not-italic"
                      dangerouslySetInnerHTML={{ __html: item?.address ?? "" }}
                    />
                    {typeof item?.email !== "undefined" && (
                      <a
                        className="inline-block font-medium leading-relaxed hover:text-gray-200"
                        href={`mailto:${item?.email}`}
                      >
                        {item?.email}
                      </a>
                    )}
                    {typeof item?.phone !== "undefined" && (
                      <>
                        <br />
                        <a
                          className="inline-block font-medium leading-relaxed hover:text-gray-200"
                          href={`tel:${item?.phone}`}
                        >
                          {item?.phone}
                        </a>
                      </>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </Container>
      </div>
      <div className="bg-danger py-8 text-center">
        <blockquote className="px-6 antialiased">
          <p className="mb-2 text-xl tracking-wider">
            {fields?.quote?.content}
          </p>
          <cite className="text-sm">
            &mdash;{" "}
            <strong>
              {fields?.quote?.title} | {fields?.quote?.designation}
            </strong>
          </cite>
        </blockquote>
      </div>
      <Container className="py-4">
        <div className="items-center justify-between sm:flex">
          <div className="mb-4 w-[140px] sm:mb-0">
            <Logo />
          </div>
          <div className="mt-4 text-sm tracking-wider sm:mt-0 sm:text-right">
            <Link className="hover:text-gray-200" to="/">
              Privacy policy
            </Link>{" "}
            |{" "}
            <Link className="hover:text-gray-200" to="/">
              Terms &amp; Conditions
            </Link>
            <p className="mt-2 text-xs tracking-wider text-gray-400">
              &copy;{new Date().getFullYear()}{" "}
              {data.settings?.generalSettings?.title}, All rights reserved.
            </p>
          </div>
        </div>
      </Container>
    </footer>
  );
}
